import { useState } from "react";
import { Button, Icon, Table } from "semantic-ui-react";

import { Option } from "models/Option";
import CarrierCapacityFormModal from "../../forms/CarrierCapacityForm/CarrierCapacityFormModal";
import { useGetCarrierCapacities } from "components/CarrierCapacity/hooks/useGetCarrierCapacities";
import CarrierPreferredLaneTableRow from "./CarrierPreferredLaneTableRow";
import CarrierCapacityLoadingTableRow from "../CarrierCapacityLoadingTableRow";
import { getDefaultCarrierCapacityFormObject } from "../../forms/CarrierCapacityForm/utils/carrierCapacityFormDefaultFormObjects";

type CarrierPreferredLaneTableProps = {
  carrierId: number;
  carrierName: string;
  equipmentOptions: Option<number>[];
};

const CarrierPreferredLaneTable = (props: CarrierPreferredLaneTableProps) => {
  const { data: preferredLanes, mutate } = useGetCarrierCapacities({
    carrierId: props.carrierId,
    filter: { types: ["Preferred Lane"] },
  });
  const [isAddingPreferredLane, setIsAddingPreferredLane] = useState(false);

  return (
    <div>
      <header>
        <h2>Preferred Lane</h2>
        <div>
          <Button primary onClick={() => setIsAddingPreferredLane(true)}>
            Add Preferred Lane
          </Button>
        </div>
      </header>

      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell>Origin</Table.HeaderCell>
            <Table.HeaderCell>Destination</Table.HeaderCell>
            <Table.HeaderCell>
              Expiration Date <Icon name="caret down" />
            </Table.HeaderCell>
            <Table.HeaderCell>Equipment</Table.HeaderCell>
            <Table.HeaderCell>Rate</Table.HeaderCell>
            <Table.HeaderCell>Note</Table.HeaderCell>
            <Table.HeaderCell>User Entered</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {preferredLanes ? (
            preferredLanes.map((preferredLane) => (
              <CarrierPreferredLaneTableRow
                key={preferredLane.id}
                carrierId={props.carrierId}
                carrierName={props.carrierName}
                preferredLane={preferredLane}
                equipmentOptions={props.equipmentOptions}
                reloadCapacities={mutate}
              />
            ))
          ) : (
            <CarrierCapacityLoadingTableRow columns={9} />
          )}
        </Table.Body>
      </Table>

      {isAddingPreferredLane ? (
        <CarrierCapacityFormModal
          {...props}
          reloadCapacities={mutate}
          onClose={() => setIsAddingPreferredLane(false)}
          defaultValues={getDefaultCarrierCapacityFormObject("Preferred Lane")}
        />
      ) : null}
    </div>
  );
};

export default CarrierPreferredLaneTable;
