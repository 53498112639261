import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "api/activitiesAPI";
import { Activity } from "../../models/Activity";

export const fetchActivities = createAsyncThunk(
  "activities/fetch",
  async ({
    profileType,
    profileId,
    page,
    pageSize,
  }: API.IActivities): Promise<{
    entities: Activity[];
    filteredCount: number;
  }> => {
    const response = await API.fetchActivities({
      profileType,
      profileId,
      page,
      pageSize,
    });
    return response.data;
  }
);

export const addNote = createAsyncThunk(
  "activities/create",
  async ({ profileType, profileId, note }: API.INote) => {
    await API.createNote({ profileType, profileId, note });
  }
);

export interface ActivitiesState {
  activities: {
    entities: Activity[];
    filteredCount: number;
  };
  activitiesLoading: boolean;
  noteLoading: boolean;
}

const initialState: ActivitiesState = {
  activities: {
    entities: [],
    filteredCount: 0,
  },
  activitiesLoading: false,
  noteLoading: false,
};

export const activitiesSlice = createSlice({
  name: "activities",
  initialState: initialState,
  reducers: {
    clearActivities: (state) => {
      state.activities.entities = [];
      state.activities.filteredCount = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchActivities.pending, (state) => {
      state.activitiesLoading = true;
    });

    builder.addCase(fetchActivities.fulfilled, (state, action) => {
      state.activities.entities =
        action.meta.arg.page === 1
          ? action.payload.entities
          : state.activities.entities.concat(action.payload.entities);

      state.activities.filteredCount = action.payload.filteredCount;
      state.activitiesLoading = false;
    });

    builder.addCase(fetchActivities.rejected, (state) => {
      state.activitiesLoading = false;
    });

    builder.addCase(addNote.pending, (state) => {
      state.noteLoading = true;
    });

    builder.addCase(addNote.fulfilled, (state) => {
      state.noteLoading = false;
    });

    builder.addCase(addNote.rejected, (state) => {
      state.noteLoading = false;
    });
  },
});

export const { clearActivities } = activitiesSlice.actions;

export const reducer = activitiesSlice.reducer;
