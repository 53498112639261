import { KeyedMutator } from "swr";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Button, Modal } from "semantic-ui-react";

import CarrierCapacityForm, {
  CarrierCapacityFormObject,
  CarrierCapacityFormType,
} from "./CarrierCapacityForm";
import { Option } from "models/Option";
import { CarrierCapacityInfoDto } from "models/dto/CarrierCapacityInfoDto";
import { mapFormToApiObject } from "./utils/carrierCapacityFormToApiMappers";
import {
  postCarrierCapacity,
  putCarrierCapacity,
} from "../../api/carrierCapacityApi";

type CarrierCapacityFormModalProps<T extends CarrierCapacityFormType> = {
  carrierId: number;
  carrierName: string;
  equipmentOptions: Option<number>[];
  onClose: () => void;
  defaultValues: CarrierCapacityFormObject;
  reloadCapacities: KeyedMutator<CarrierCapacityInfoDto<T>[]>;
  originalCapacity?: CarrierCapacityInfoDto<T>;
};

const capacityTypeToTextMap: Record<CarrierCapacityFormType, string> = {
  Capacity: "Capacity",
  CantCover: "Can't Cover",
  "Preferred Lane": "Preferred Lane",
};

const CarrierCapacityFormModal = <T extends CarrierCapacityFormType>(
  props: CarrierCapacityFormModalProps<T>
) => {
  const {
    control,
    errors,
    setValue,
    handleSubmit,
    register,
    formState: { isSubmitting },
  } = useForm<CarrierCapacityFormObject>({
    defaultValues: props.defaultValues,
  });

  register({ name: "id" });
  register({ name: "type" });

  const onSubmit = async (formObject: CarrierCapacityFormObject) => {
    const apiObject = mapFormToApiObject({
      formObject,
      capacity: props.originalCapacity,
    });

    if (!apiObject) {
      toast.error(
        "There was an error in form validation.  Please check your entries and try again."
      );
    } else {
      try {
        apiObject.id
          ? await putCarrierCapacity({
              carrierId: props.carrierId,
              data: apiObject,
            })
          : await postCarrierCapacity({
              carrierId: props.carrierId,
              data: apiObject,
            });

        toast.success("Capacity saved successfully");

        props.reloadCapacities();
        props.onClose();
      } catch (error) {
        console.error(error);
        toast.error("There was an error saving capacity");
      }
    }
  };

  return (
    <Modal size="large" open onClose={props.onClose}>
      <Modal.Header>
        {props.defaultValues.id ? "Edit" : "Add"}{" "}
        {capacityTypeToTextMap[props.defaultValues.type]} - {props.carrierName}
      </Modal.Header>

      <Modal.Content>
        <CarrierCapacityForm
          control={control}
          errors={errors}
          setValue={setValue}
          closeForm={props.onClose}
          defaultValues={props.defaultValues}
          equipmentOptions={props.equipmentOptions}
        />
      </Modal.Content>

      <Modal.Actions>
        <Button type="button" negative onClick={props.onClose}>
          Cancel
        </Button>
        <Button
          type="submit"
          primary
          loading={isSubmitting}
          onClick={handleSubmit(onSubmit)}
        >
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default CarrierCapacityFormModal;
