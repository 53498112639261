import { useState } from "react";
import { KeyedMutator } from "swr";
import { Icon, Table } from "semantic-ui-react";

import CarrierCapacityTableRowButtons from "../CarrierCapacityTableRowButtons";
import { CarrierCapacityInfoDto } from "models/dto/CarrierCapacityInfoDto";
import { Option } from "models/Option";
import CarrierCapacityFormModal from "components/CarrierCapacity/forms/CarrierCapacityForm/CarrierCapacityFormModal";
import { mapApiToFormObject } from "components/CarrierCapacity/forms/CarrierCapacityForm/utils/carrierCapacityApiToFormMappers";

type CarrierCapacityTableRowProps = {
  carrierId: number;
  carrierName: string;
  capacity: CarrierCapacityInfoDto<"Capacity" | "CantCover">;
  equipmentOptions: Option<number>[];
  reloadCapacities: KeyedMutator<
    CarrierCapacityInfoDto<"Capacity" | "CantCover">[]
  >;
};

const CarrierCapacityTableRow = (props: CarrierCapacityTableRowProps) => {
  const [isEditingCapacity, setIsEditingCapacity] = useState(false);

  return (
    <Table.Row negative={props.capacity.type === "CantCover"}>
      <Table.Cell>
        <Icon
          link
          name="edit"
          title="Edit Capacity"
          onClick={() => setIsEditingCapacity(true)}
        />
      </Table.Cell>
      <Table.Cell>{props.capacity.origin ?? "N/A"}</Table.Cell>
      <Table.Cell>{props.capacity.destination ?? "N/A"}</Table.Cell>
      <Table.Cell>
        {props.capacity.expirationDate
          ? new Intl.DateTimeFormat(window.navigator.language).format(
              new Date(props.capacity.expirationDate)
            )
          : "N/A"}
      </Table.Cell>
      <Table.Cell>
        {props.capacity.equipmentModeCategoryId
          ? props.equipmentOptions.find(
              (option) =>
                option.value === props.capacity.equipmentModeCategoryId
            )?.text
          : "N/A"}
      </Table.Cell>
      <Table.Cell>
        {props.capacity.rate
          ? new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(props.capacity.rate)
          : "N/A"}
      </Table.Cell>
      <Table.Cell>{props.capacity.notes || "N/A"}</Table.Cell>
      <Table.Cell>{props.capacity.modifiedBy ?? "N/A"}</Table.Cell>
      <Table.Cell>
        {props.capacity.id ? (
          <CarrierCapacityTableRowButtons
            carrierId={props.carrierId}
            capacityId={props.capacity.id}
            reloadCapacities={props.reloadCapacities}
          />
        ) : null}
      </Table.Cell>

      {isEditingCapacity ? (
        <CarrierCapacityFormModal
          carrierId={props.carrierId}
          carrierName={props.carrierName}
          equipmentOptions={props.equipmentOptions}
          onClose={() => setIsEditingCapacity(false)}
          defaultValues={mapApiToFormObject(props.capacity)}
          reloadCapacities={props.reloadCapacities}
          originalCapacity={props.capacity}
        />
      ) : null}
    </Table.Row>
  );
};

export default CarrierCapacityTableRow;
