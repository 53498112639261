import { useState } from "react";
import { KeyedMutator } from "swr";
import { Icon, Table } from "semantic-ui-react";

import { Option } from "models/Option";
import { CarrierCapacityInfoDto } from "models/dto/CarrierCapacityInfoDto";
import CarrierCapacityTableRowButtons from "../CarrierCapacityTableRowButtons";

import { mapApiToFormObject } from "components/CarrierCapacity/forms/CarrierCapacityForm/utils/carrierCapacityApiToFormMappers";
import CarrierCapacityFormModal from "components/CarrierCapacity/forms/CarrierCapacityForm/CarrierCapacityFormModal";

type CarrierPreferredLaneTableRowProps = {
  carrierId: number;
  carrierName: string;
  preferredLane: CarrierCapacityInfoDto<"Preferred Lane">;
  equipmentOptions: Option<number>[];
  reloadCapacities: KeyedMutator<CarrierCapacityInfoDto<"Preferred Lane">[]>;
};

const CarrierPreferredLaneTableRow = (
  props: CarrierPreferredLaneTableRowProps
) => {
  const [isEditingPreferredLane, setIsEditingPreferredLane] = useState(false);

  return (
    <Table.Row key={props.preferredLane.id}>
      <Table.Cell>
        <Icon
          link
          name="edit"
          title="Edit Preferred Lane"
          onClick={() => setIsEditingPreferredLane(true)}
        />
      </Table.Cell>
      <Table.Cell>{props.preferredLane.origin ?? "N/A"}</Table.Cell>
      <Table.Cell>{props.preferredLane.destination ?? "N/A"}</Table.Cell>
      <Table.Cell>
        {props.preferredLane.expirationDate
          ? new Intl.DateTimeFormat(window.navigator.language).format(
              new Date(props.preferredLane.expirationDate)
            )
          : "N/A"}
      </Table.Cell>
      <Table.Cell>
        {props.preferredLane.equipmentModeCategoryId
          ? props.equipmentOptions.find(
              (option) =>
                option.value === props.preferredLane.equipmentModeCategoryId
            )?.text
          : "N/A"}
      </Table.Cell>
      <Table.Cell>
        {props.preferredLane.rate
          ? new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(props.preferredLane.rate)
          : "N/A"}
      </Table.Cell>
      <Table.Cell>{props.preferredLane.notes || "N/A"}</Table.Cell>
      <Table.Cell>{props.preferredLane.modifiedBy ?? "N/A"}</Table.Cell>
      <Table.Cell>
        {props.preferredLane.id ? (
          <CarrierCapacityTableRowButtons
            carrierId={props.carrierId}
            capacityId={props.preferredLane.id}
            reloadCapacities={props.reloadCapacities}
          />
        ) : null}
      </Table.Cell>

      {isEditingPreferredLane ? (
        <CarrierCapacityFormModal
          carrierId={props.carrierId}
          carrierName={props.carrierName}
          equipmentOptions={props.equipmentOptions}
          onClose={() => setIsEditingPreferredLane(false)}
          defaultValues={mapApiToFormObject(props.preferredLane)}
          reloadCapacities={props.reloadCapacities}
          originalCapacity={props.preferredLane}
        />
      ) : null}
    </Table.Row>
  );
};

export default CarrierPreferredLaneTableRow;
