import { useState } from "react";
import { Button, Icon, Table } from "semantic-ui-react";

import { Option } from "models/Option";
import CarrierCapacityFormModal from "../../forms/CarrierCapacityForm/CarrierCapacityFormModal";
import { useGetCarrierCapacities } from "components/CarrierCapacity/hooks/useGetCarrierCapacities";
import CarrierCapacityTableRow from "./CarrierCapacityTableRow";
import CarrierCapacityLoadingTableRow from "../CarrierCapacityLoadingTableRow";
import { getDefaultCarrierCapacityFormObject } from "../../forms/CarrierCapacityForm/utils/carrierCapacityFormDefaultFormObjects";

type CarrierCapacityTableProps = {
  carrierId: number;
  carrierName: string;
  equipmentOptions: Option<number>[];
  updateCarrierTimeline: (note: string) => void;
};

const CarrierCapacityTable = (props: CarrierCapacityTableProps) => {
  const { data: capacities, mutate } = useGetCarrierCapacities({
    carrierId: props.carrierId,
    filter: { types: ["Capacity", "CantCover"] },
  });
  const [isAddingCapacity, setIsAddingCapacity] = useState(false);
  const [isAddingCantCover, setIsAddingCantCover] = useState(false);

  return (
    <div>
      <header>
        <h2>Capacity</h2>
        <div>
          <Button primary onClick={() => setIsAddingCapacity(true)}>
            Add Capacity
          </Button>
          <Button color="grey" onClick={() => setIsAddingCantCover(true)}>
            Can't Cover
          </Button>
          <Button
            color="red"
            onClick={() =>
              props.updateCarrierTimeline(
                `${props.carrierName} does not have capacity`
              )
            }
          >
            No Capacity
          </Button>
          <Button onClick={() => props.updateCarrierTimeline("Left voicemail")}>
            Left Voicemail
          </Button>
        </div>
      </header>

      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell>Origin</Table.HeaderCell>
            <Table.HeaderCell>Destination</Table.HeaderCell>
            <Table.HeaderCell>
              Date <Icon name="caret down" />
            </Table.HeaderCell>
            <Table.HeaderCell>Equipment</Table.HeaderCell>
            <Table.HeaderCell>Rate</Table.HeaderCell>
            <Table.HeaderCell>Note</Table.HeaderCell>
            <Table.HeaderCell>User Entered</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {capacities ? (
            capacities.map((capacity) => (
              <CarrierCapacityTableRow
                key={capacity.id}
                carrierId={props.carrierId}
                carrierName={props.carrierName}
                capacity={capacity}
                equipmentOptions={props.equipmentOptions}
                reloadCapacities={mutate}
              />
            ))
          ) : (
            <CarrierCapacityLoadingTableRow columns={9} />
          )}
        </Table.Body>
      </Table>

      {isAddingCapacity ? (
        <CarrierCapacityFormModal
          {...props}
          reloadCapacities={mutate}
          onClose={() => setIsAddingCapacity(false)}
          defaultValues={getDefaultCarrierCapacityFormObject("Capacity")}
        />
      ) : null}

      {isAddingCantCover ? (
        <CarrierCapacityFormModal
          {...props}
          reloadCapacities={mutate}
          onClose={() => setIsAddingCantCover(false)}
          defaultValues={getDefaultCarrierCapacityFormObject("CantCover")}
        />
      ) : null}
    </div>
  );
};

export default CarrierCapacityTable;
