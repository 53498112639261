import { CarrierPlanCapacityFormObject } from "../CarrierPlanCapacityForm";

export const getDefaultCarrierPlanCapacityFormObject =
  (): CarrierPlanCapacityFormObject => ({
    id: "",
    planId: null,
    laneId: null,
    rate: "",
    equipment: null,
    coverageAssigned: "",
    note: "",
  });
