import { useState } from "react";
import { KeyedMutator } from "swr";
import { Icon, Table } from "semantic-ui-react";

import { CarrierCoveragePlanCapacityDto } from "../../../../models/dto/CarrierCoveragePlanCapacityDto";
import { useGetCoveragePlan } from "pages/CoveragePlans/CoveragePlanForm/api/hooks/useGetCoveragePlan";
import CarrierPlanCapacityFormModal from "components/CarrierCapacity/forms/CarrierPlanCapacityForm/CarrierPlanCapacityFormModal";
import { mapApiToFormObject } from "components/CarrierCapacity/forms/CarrierPlanCapacityForm/utils/carrierPlanCapacityApiToFormMappers";
import CarrierCapacityLoadingTableRow from "../CarrierCapacityLoadingTableRow";

type CarrierPlanCapacityTableRowProps = {
  carrierId: number;
  carrierName: string;
  planCapacity: CarrierCoveragePlanCapacityDto;
  reloadCapacities: KeyedMutator<CarrierCoveragePlanCapacityDto[]>;
};

const formatCurrency = (value: number) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  }).format(value);

const CarrierPlanCapacityTableRow = (
  props: CarrierPlanCapacityTableRowProps
) => {
  const [isEditingCapacity, setIsEditingCapacity] = useState(false);
  const { data } = useGetCoveragePlan(props.planCapacity.planId);

  const lane = data?.lanes.find(
    (lane) => lane.id === props.planCapacity.laneId
  );

  const isEditEnabled = data?.status === "Active";

  return data && lane ? (
    <Table.Row>
      <Table.Cell>
        <Icon
          name="edit"
          title={isEditEnabled ? "Edit Plan Capacity" : "Plan is Inactive"}
          link={isEditEnabled}
          disabled={!isEditEnabled}
          onClick={() => setIsEditingCapacity(true)}
        />
      </Table.Cell>
      <Table.Cell>{data.name}</Table.Cell>
      <Table.Cell
        title={`${lane.origin} → ${lane.destination}`}
      >{`${lane.origin} → ${lane.destination}`}</Table.Cell>
      <Table.Cell>{props.planCapacity.equipment.name}</Table.Cell>
      <Table.Cell>{`${props.planCapacity.coverageAssigned} / ${data.frequency}`}</Table.Cell>
      <Table.Cell>
        {props.planCapacity.rate
          ? formatCurrency(props.planCapacity.rate)
          : "N/A"}
      </Table.Cell>
      <Table.Cell title={props.planCapacity.note}>
        {props.planCapacity.note || "N/A"}
      </Table.Cell>
      <Table.Cell>{props.planCapacity.lastModifiedUser}</Table.Cell>

      {isEditingCapacity ? (
        <CarrierPlanCapacityFormModal
          onClose={() => setIsEditingCapacity(false)}
          defaultValues={mapApiToFormObject(props.planCapacity)}
          carrierId={props.carrierId}
          carrierName={props.carrierName}
          reloadCapacities={props.reloadCapacities}
        />
      ) : null}
    </Table.Row>
  ) : (
    <CarrierCapacityLoadingTableRow columns={8} />
  );
};

export default CarrierPlanCapacityTableRow;
