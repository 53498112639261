import { useState } from "react";
import { Button, Table } from "semantic-ui-react";

import CoveragePlanCapacityRow from "components/CarrierCapacity/tables/CarrierPlanCapacityTable/CarrierPlanCapacityTableRow";
import { useGetCarrierPlanCapacities } from "components/CarrierCapacity/hooks/useGetCarrierPlanCapacities";
import CarrierPlanCapacityFormModal from "../../forms/CarrierPlanCapacityForm/CarrierPlanCapacityFormModal";
import { getDefaultCarrierPlanCapacityFormObject } from "../../forms/CarrierPlanCapacityForm/utils/carrierPlanCapacityDefaultFormObjects";
import CarrierCapacityLoadingTableRow from "../CarrierCapacityLoadingTableRow";

type CarrierPlanCapacityTableProps = {
  carrierId: number;
  carrierName: string;
  updateCarrierTimeline: (note: string) => void;
};

const CarrierPlanCapacityTable = (props: CarrierPlanCapacityTableProps) => {
  const [isAddingPlanCapacity, setIsAddingPlanCapacity] = useState(false);
  const { data: planCapacities, mutate } = useGetCarrierPlanCapacities({
    carrierId: props.carrierId,
  });

  return (
    <div>
      <header>
        <h2>Plan Capacity</h2>
        <div>
          <Button primary onClick={() => setIsAddingPlanCapacity(true)}>
            Add Plan Capacity
          </Button>
          <Button
            color="red"
            onClick={() =>
              props.updateCarrierTimeline(
                `${props.carrierName} does not have plan capacity`
              )
            }
          >
            No Plan Capacity
          </Button>
        </div>
      </header>

      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell>Plan Name</Table.HeaderCell>
            <Table.HeaderCell>Plan Lane</Table.HeaderCell>
            <Table.HeaderCell>Equipment</Table.HeaderCell>
            <Table.HeaderCell>Trucks</Table.HeaderCell>
            <Table.HeaderCell>Rate</Table.HeaderCell>
            <Table.HeaderCell>Note</Table.HeaderCell>
            <Table.HeaderCell>User Entered</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {planCapacities ? (
            planCapacities.map((planCapacity) => (
              <CoveragePlanCapacityRow
                key={planCapacity.id}
                planCapacity={planCapacity}
                carrierId={props.carrierId}
                carrierName={props.carrierName}
                reloadCapacities={mutate}
              />
            ))
          ) : (
            <CarrierCapacityLoadingTableRow columns={8} />
          )}
        </Table.Body>
      </Table>

      {isAddingPlanCapacity ? (
        <CarrierPlanCapacityFormModal
          onClose={() => setIsAddingPlanCapacity(false)}
          defaultValues={getDefaultCarrierPlanCapacityFormObject()}
          carrierId={props.carrierId}
          carrierName={props.carrierName}
          reloadCapacities={mutate}
        />
      ) : null}
    </div>
  );
};

export default CarrierPlanCapacityTable;
