import {
  CarrierCapacityFormObject,
  CarrierCapacityFormType,
} from "../CarrierCapacityForm";

export const getDefaultCarrierCapacityFormObject = (
  type: CarrierCapacityFormType
): CarrierCapacityFormObject => ({
  id: null,
  type,
  date: "",
  origin: "",
  destination: "",
  rate: "",
  equipmentModeCategoryId: null,
  note: "",
});
