import { CarrierCapacityInfoDto } from "models/dto/CarrierCapacityInfoDto";
import {
  CarrierCapacityFormObject,
  CarrierCapacityFormType,
} from "../CarrierCapacityForm";

export const getCapacityDates = ({
  type,
  date,
}: Pick<CarrierCapacityFormObject, "type" | "date">): {
  availabilityDate: string;
  expirationDate: string | null;
} => {
  let expirationDate = new Date();

  switch (type) {
    case "Capacity":
    case "CantCover":
      expirationDate = new Date(date);
      expirationDate.setDate(expirationDate.getDate() + 1);
      break;
    case "Preferred Lane":
      expirationDate.setFullYear(expirationDate.getFullYear() + 1);
      break;
    default:
      break;
  }

  expirationDate.setHours(23);
  expirationDate.setMinutes(59);
  expirationDate.setSeconds(59);
  expirationDate.setMilliseconds(0);

  return {
    availabilityDate: new Intl.DateTimeFormat("fr-CA").format(new Date()),
    expirationDate: expirationDate.toISOString(),
  };
};

export const mapFormToApiObject = ({
  formObject,
  capacity,
}: {
  formObject: CarrierCapacityFormObject;
  capacity?: CarrierCapacityInfoDto<CarrierCapacityFormType>;
}): Partial<CarrierCapacityInfoDto<CarrierCapacityFormType>> | null => {
  if (
    !formObject.type ||
    !formObject.origin ||
    !formObject.equipmentModeCategoryId
  ) {
    return null;
  }

  if (
    (formObject.type === "Capacity" || formObject.type === "CantCover") &&
    !formObject.date
  ) {
    return null;
  }

  if (
    (formObject.type === "CantCover" || formObject.type === "Preferred Lane") &&
    !formObject.destination
  ) {
    return null;
  }

  return {
    ...capacity,
    id: formObject.id,
    type: formObject.type,
    origin: formObject.origin,
    destination: formObject.destination || null,
    rate: formObject.rate ? Number(formObject.rate) : null,
    equipmentModeCategoryId: formObject.equipmentModeCategoryId,
    notes: formObject.note,
    ...getCapacityDates(formObject),
  };
};
